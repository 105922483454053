import { CustomCircle } from "@/apps/Shared/CustomCircle/CustomCircle";
import {
  capitalizeName,
  formtDate,
} from "@/apps/Vank/Page/utils/Utilfunctions";
import {
  IconArrowLeft,
  IconClose,
  IconNoMovement,
} from "@/assets/IconV2/IconsSvg";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import ServiceFiat from "../../Services/ServicesFiat";
import { formatBalance } from "@/apps/Vank/Page/Home/Home";
import CustomDataLoading from "@/apps/Shared/CustomDataLoading/CustomDataLoading";
import CustomModal from "@/apps/Shared/CustomModal/CustomModal";
import ModalApprovedShipping from "../../components/ModalApprovedShipping/ModalApprovedShipping";
import { AnimatePresence, motion } from "framer-motion";
import ModalFeeTransaction from "../../components/ModalFeeTransaction/ModalFeeTransaction";

export const formatItemAmount = (
  amount: string,
  discount: string,
  asset: string,
  name: string
) => {
  // Formatear el monto con coma para miles y punto para decimales
  const formattedAmount = parseFloat(amount)
    .toFixed(2) // Asegura que tenga dos decimales
    .replace(/\d(?=(\d{3})+\.)/g, "$&,") // Agrega coma para miles y punto para decimales
    .replace(".", "."); // Reemplaza punto por coma para decimales

  // Formatear el descuento con coma para miles y punto para decimales
  const formattedDiscount = parseFloat(discount)
    .toFixed(2) // Asegura que tenga dos decimales
    .replace(/\d(?=(\d{3})+\.)/g, "$&,") // Agrega coma para miles y punto para decimales
    .replace(".", "."); // Reemplaza punto por coma para decimales

  // Verificar si es "Fee" o no
  if (name.split(" ")[0] === "Fee") {
    return `-${formattedDiscount} ${asset}`;
  } else {
    return `-$${formattedAmount} ${asset}`;
  }
};

function ModalTableMovementsMobilFiat({
  Onclose,
  className,
  capitalizeWords,
}: {
  Onclose: any;
  className?: string;
  capitalizeWords: any;
}) {
  const fiatService = new ServiceFiat();
  const [fill, setFill] = useState<string>("#818282");
  const [t] = useTranslation("global");
  const [dataTransaction, setDataTransaction] = useState<any[]>([]);
  const [loading, setLoading] = useState<boolean>(true); // Estado de carga
  const [modalInfo, setModalInfo] = useState(false);
  const [infoItem, setInfoItem] = useState<null>(null);
  const [modalInfo2, setModalInfo2] = useState(false);

  // summary modal states
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [info, setInfo] = useState(null); // Estado para información de gastos

  const fetchData = async () => {
    try {
      const response = await fiatService.getDataTransactionSendFormFiat();
      const formattedData = response.body.map((item) => ({
        ...item,
        formattedAmount: new Intl.NumberFormat("en-US", {
          maximumFractionDigits: 8,
        }).format(parseFloat(item.AMOUNT)),
      }));
      return formattedData;
    } catch (error) {
      toast.error("Error");
      return [];
    }
  };

  useEffect(() => {
    const fetchDataAndSetState = async () => {
      setLoading(true); // Comienza la carga
      const data = await fetchData();
      setDataTransaction(data);
      setLoading(false); // Termina la carga
    };

    fetchDataAndSetState();
  }, []);

  const handleItemClick = (item: any) => {
    setInfoItem(item);
    setModalInfo(true);
  };

  const handleItemClick2 = (item) => {
    // return
    setInfoItem(item);
    // setInfoItem({ ...item, STATUSORDER: 1 });
    setModalInfo2(true);
  };

  const handleSelect = (item: any) => {
    setIsModalOpen(true);
    // Eliminar valores anteriores
    // setInfo({ ...item, STATUSORDER: 4 });
    setInfo(item);
    // Mostrar la modal correspondiente según el tipo de transacción
  };

  const _className = `${
    className ? className : ""
  }mobile:w-full mobile:absolute  bottom-0 bg-white dark:bg-[var(--background-component-module-dark)] flex-col justify-start items-start inline-flex pb-1 rounded-t-[16px] min-h-[50%] h-[90%] max-h-[88%] `;

  return (
    <>
      <motion.div
        className={_className}
        initial={
          !isModalOpen || !modalInfo2
            ? { translateY: "100%" }
            : { translateY: 0 }
        } // Solo en móvil comienza fuera de la pantalla
        animate={
          isModalOpen || modalInfo2 ? { translateY: "100%" } : { translateY: 0 }
        } // Siempre termina en su posición original
        exit={
          !isModalOpen || !modalInfo2
            ? { translateY: "100%" }
            : { translateY: 0 }
        } // Sale hacia abajo solo en móvil
        transition={{ duration: 0.5 }} // Duración y suavizado de la transición
      >
        <div className="w-full h-[60px] px-4 flex justify-between items-center gap-6 border-b border-[#F1F1F1] dark:border-[#2D2E2F]">
          <p className="text-[#2D2E2F] dark:text-[#FFFFFF] text-sm font-medium">
            {t("Vank.Share.LatestTransactions.Header.Title")}
          </p>
          <div className="flex items-center justify-center gap-4">
            <div className="btn-view-all 2xl:text-[14px] xl:text-[14px] lg:text-[14px] tablet:text-[14px] mobile:text-[10px] dark:text-[#C0C0C1]">
              {t("Vank.Share.LatestTransactions.Header.ViewMore")}
            </div>
            <div
              onMouseEnter={() => setFill("white")}
              onMouseLeave={() => setFill("#818282")}
              onClick={Onclose}
              className={`h-8 w-8 cursor-pointer flex items-center p-0 border border-[#F1F1F1] dark:border-[#212222] rounded-full hover:bg-[--color-darkest-grey]`}
            >
              <IconClose fill={fill} />
            </div>
          </div>
        </div>

        {/* <div className="w-full flex flex-col gap-4  h-full "> */}
        <div className="h-full w-full flex flex-col bg-[var(--bacground-component-module-light)] dark:bg-[var(--background-component-module-dark)] overflow-y-auto max-h-[80vh] border-t border-[#F1F1F1] dark:border-[#212222]">
          {loading ? (
            <div className="w-full min-h-[224px] h-full flex justify-center items-center">
              <CustomDataLoading />
            </div>
          ) : Array.isArray(dataTransaction) && dataTransaction.length > 0 ? (
            dataTransaction.map((item, index) => (
              <motion.div
                className=""
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.9 }}
                key={index}
              >
                <div
                  className="w-full h-[52px] px-[16px] py-[10px] flex justify-between items-center group hover:scale-[0.99] transition-all duration-300"
                  onClick={() => handleSelect(item)}
                >
                  <div className="flex items-center gap-[8px]">
                    <CustomCircle
                      moreStyle={`${
                        item.NAME.split(" ")[0] === "Fee"
                          ? "bg-[var(--error-bg-light)]"
                          : "bg-[var(--error-bg-light)]"
                      } min-w-[32px] h-[32px] rotate-180 flex justify-center items-center`}
                      icon={
                        item.NAME.split(" ")[0] === "Fee" ? (
                          <span
                            style={{
                              color: "var(--content-inner-inner-black-light)",
                            }}
                          >
                            {" "}
                            %{" "}
                          </span>
                        ) : (
                          <IconArrowLeft
                            className={` ${
                              item.ACTION === "OUTPUT"
                                ? "-rotate-[50deg]"
                                : "rotate-[136deg]"
                            } w-[15px] h-[15px]`}
                            stroke="var(--content-inner-inner-black-light)"
                          />
                        )
                      }
                    />
                    <div className="relative w-full h-[32px] flex flex-col justify-start items-start gap-[6px] ">
                      <span
                        className={`text-[12px] text-ellipsis overflow-visible whitespace-nowrap 
                                      ${
                                        item.STATUSORDER === 3 ||
                                        item.STATUSORDER === 4
                                          ? "text-[var(--content-disabled-input)]"
                                          : "text-[var(--content-main-black-light)] dark:text-[var(--content-main-black-dark)]"
                                      } leading-[14.52px]`}
                      >
                        {item.NAME.split(" ")[0] === "Fee"
                          ? t(
                              "Vank.Transaction.Money.Tables.TableTransactionFiat.text4"
                            ) + ` ${item.FEE * 100}%`
                          : capitalizeName(
                              ` ${item.NAME.split(" ")[0]} ${
                                item.SURNAME ? item.SURNAME.split(" ")[0] : ""
                              }`
                            )}
                      </span>
                      <div className="flex items-center gap-[4px]">
                        <span
                          className={`whitespace-nowrap text-[10px] w-full flex items-center ${
                            item.STATUSORDER === 3 || item.STATUSORDER === 4
                              ? "text-[var(--content-disabled-input)]"
                              : "text-[var(--content-main-black-light)] dark:text-[var(--content-main-black-dark)]"
                          } leading-[12.1px]`}
                        >
                          {t(
                            "Vank.Transaction.Money.Tables.TableTransactionFiat.SendFiatComplete"
                          )}
                        </span>
                        <span
                          className={`max-w-max h-[12px] flex justify-center items-center font-medium rounded-[3px] px-[6px] text-[8px] leading-[9.68px] ${
                            item.STATUSORDER == 1
                              ? "bg-[#E1FDE4] text-[#1F5928]"
                              : item.STATUSORDER == 2
                              ? "bg-[#FFF3DB] text-[#824215]"
                              : item.STATUSORDER == 3
                              ? "bg-[#FDE1E1] text-[#731C1C]"
                              : "bg-[#FDE1E1] text-[#731C1C]"
                          }`}
                        >
                          {item.STATUSORDER == 1 &&
                            t("Vank.Share.LatestTransactions.Header.Done")}
                          {item.STATUSORDER == 2 &&
                            t("Vank.Share.LatestTransactions.Header.Pending")}
                          {item.STATUSORDER == 3 &&
                            t("Vank.Share.LatestTransactions.Header.Failed")}
                          {(item.STATUSORDER == 5 || item.STATUSORDER == 4) &&
                            t("Vank.Share.LatestTransactions.Header.Failed")}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="flex items-center gap-[8px]">
                    <div
                      className={`xl:flex 2xl:flex-shrink-0 xl:flex-shrink-0 text-[12px]  font-semibold whitespace-nowrap ${
                        item.STATUSORDER === 3 || item.STATUSORDER === 4
                          ? "line-through text-[var(--content-disabled-input)]"
                          : "text-[var(--content-main-black-light)] dark:text-[#FFFFFF]"
                      }`}
                    >
                      {formatItemAmount(
                        item.AMOUNT,
                        item.DISACOUNT,
                        item.ASSETNAME,
                        item.NAME
                      )}
                    </div>
                  </div>
                </div>
                {item.STATUSORDER != 1 && (
                  <div className="w-full min-h-px bg-[#F1F1F1] dark:bg-[#212222]" />
                )}
                <div
                  onClick={() => handleItemClick2(item)}
                  className={`${
                    item.STATUSORDER != 1
                      ? "hidden"
                      : "w-full h-[56px] px-[16px] py-[10px] flex justify-between items-center group hover:scale-[0.99] transition-all duration-300"
                  }`}
                >
                  <div className="flex items-center gap-[8px]">
                    <span>
                      <svg
                        className=" stroke-[#2D2E2F] dark:stroke-[#fff]"
                        xmlns="http://www.w3.org/2000/svg"
                        width="36"
                        height="36"
                        viewBox="0 0 36 36"
                        fill="none"
                      >
                        <rect
                          x="1"
                          y="1"
                          width="34"
                          height="34"
                          rx="17"
                          stroke="#FDE1E1"
                          strokeWidth="2"
                        />
                        <path
                          d="M22.3197 13.6797L13.6797 22.3197M20.8797 22.3197C20.0844 22.3197 19.4397 21.675 19.4397 20.8797C19.4397 20.0844 20.0844 19.4397 20.8797 19.4397C21.675 19.4397 22.3197 20.0844 22.3197 20.8797C22.3197 21.675 21.675 22.3197 20.8797 22.3197ZM15.1197 16.5597C14.3244 16.5597 13.6797 15.915 13.6797 15.1197C13.6797 14.3244 14.3244 13.6797 15.1197 13.6797C15.915 13.6797 16.5597 14.3244 16.5597 15.1197C16.5597 15.915 15.915 16.5597 15.1197 16.5597Z"
                          stroke=""
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </span>
                    <span
                      className={`text-[12px] text-ellipsis w-full  overflow-visible whitespace-nowrap ${
                        item.STATUSORDER === 3 || item.STATUSORDER === 4
                          ? "text-[var(--content-disabled-input)]"
                          : "text-[var(--content-main-black-light)] dark:text-[#FFFFFF] "
                      } leading-none`}
                    >
                      {t(
                        "Vank.Transaction.Money.Tables.TableTransactionFiat.text4"
                      )}
                    </span>
                  </div>
                  <p
                    className={`flex gap-2 text-[12px] whitespace-nowrap font-semibold ${
                      item.STATUSORDER === 3 || item.STATUSORDER === 4
                        ? "line-through text-[var(--content-disabled-input)]"
                        : "text-[var(--content-main-black-light)] dark:text-[#FFFFFF]"
                    } text-[12px]`}
                  >
                    {formatBalance(item.DISACOUNT)} {item.ASSETNAME}{" "}
                    <span className="text-[#818282] dark:text-[#C0C0C1] text-[12px]"> ({item.FEE}%)</span>
                  </p>
                </div>
                {item.STATUSORDER === 1 && (
                  <div className="w-full min-h-px bg-[#F1F1F1] dark:bg-[#212222]" />
                )}
              </motion.div>
            ))
          ) : (
            <div className="w-full min-h-[224px] h-full flex flex-col justify-center items-center">
              <div className="w-full h-full flex flex-col justify-center items-center rounded-2xl text-[#818282] dark:text-[#C0C0C1]">
                <IconNoMovement />
                {t("Mobile.TransactionHistory.IconNoMovement")}
              </div>
            </div>
          )}
        </div>
        {/* </div> */}
      </motion.div>

      <AnimatePresence>
        {isModalOpen && (
          <ModalApprovedShipping
            isMoneyModalOpen={isModalOpen}
            data={info}
            onClose={() => setIsModalOpen(false)}
          />
        )}
      </AnimatePresence>
      <CustomModal isOpen={modalInfo2}>
        <ModalFeeTransaction
          isMoneyModalOpen={modalInfo2}
          data={infoItem}
          onClose={() => setModalInfo2(false)}
          // isMoneyModalOpen={false}
        />
      </CustomModal>
    </>
  );
}

export default ModalTableMovementsMobilFiat;
