import { Navigate, Outlet, useNavigate } from "react-router-dom";
import Header from "../../Landing/Header/Header";
import Footer from "../../Landing/Footer/Footer";
import IconScrollToTop from "@/assets/Icon/IconScrollToTop";
import ScrollToTop from "react-scroll-to-top";
import { useEffect, useState } from "react";
import { CacheDropDowns } from "@/apps/Shared/infrastructura/Persistence/CacheDropDowns";
import '../RootVank/RootVank.css'

const RootLanding = () => {

  const cacheDropdowns = new CacheDropDowns();
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate()

  useEffect(() => {
    setIsLoading(true);

    cacheDropdowns
      .getUser()
      .then(() => {
        setIsLoading(false);
        // navigate("/Home");
      })
      .catch((e) => {
        setIsLoading(false);
      });

    // document.addEventListener("session-expired", () => setOpenDrop(true));
  }, []);

  return (
    <>
      {isLoading ? (
        <div className="w-full h-screen min-w-[1440px] bg-[--color-lighter-white] justify-center items-center inline-flex overflow-hidden">
          <svg className="loader" viewBox="0 0 384 384" xmlns="http://www.w3.org/2000/svg">
            <circle
              className="active"
              pathLength="360"
              fill="transparent"
              strokeWidth="32"
              cx="192"
              cy="192"
              r="176"
            ></circle>
            <circle
              className="track"
              pathLength="360"
              fill="transparent"
              strokeWidth="32"
              cx="192"
              cy="192"
              r="176"
            ></circle>
          </svg>
        </div>
      ) : (
        <>
          <Header />
          <Outlet />
          <ScrollToTop
            smooth
            top={410}
            className="bg-transparent"
            style={{
              backgroundColor: "#161616",
              color: "#FFED00",
              width: 46,
              height: 46,
              borderRadius: "100%",
              boxShadow: "none",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              zIndex: 20,
            }}
            component={<IconScrollToTop />}
          />
          <Footer />
        </>
      )}
    </>
  );
};

export default RootLanding;
