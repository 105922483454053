import CustomModal from "@/apps/Shared/CustomModal/CustomModal";
import { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useRecoilState } from "recoil";
import Convert from "./manners/Convert/Convert";
import ModalInfoTransaction from "../../shared/ModalInfoTransaction/ModalInfoTransaction";
import { formatBalance } from "../../Home/Home";
import { SelectTransaction } from "./state";
import { counterSelector, ProfileUser } from "../../Home/state";
import { CustomCircle } from "@/apps/Shared/CustomCircle/CustomCircle";
import {
  IconArrowLeft,
  IconNoMovement,
  IconVankPayTransactions,
} from "@/assets/IconV2/IconsSvg";
// import { serviceOrchestrator } from "@/apps/services/ServicesOrchestrator";
import { ServiceOrchestrator } from "../../Transactions/VankPay/service/ServicesOrchestrator";
import { ServicesHome } from "../../Home/Services/ServicesHome";
import { formtDate } from "../../utils/Utilfunctions";
import ModalApprovedShipping from "../../Transactions/Fiat/components/ModalApprovedShipping/ModalApprovedShipping";
import ModalTableMovementsMobil from "./modals/ModalTableMovementsMobil";
import { useTheme } from "@/Context/UseContext/ThemeContext";
import CustomDataLoading from "@/apps/Shared/CustomDataLoading/CustomDataLoading";
import { motion } from "framer-motion";
import "./state/style.css";
import { useQueryClient } from "@tanstack/react-query";
import { updateBalnceAtom } from "@/apps/Shared/layout/Header/UserProfileAndNotifications/atoms/updateBalnceAtom";
import ModalFeeTransaction from "../../Transactions/Fiat/components/ModalFeeTransaction/ModalFeeTransaction";

// Función para capitalizar nombres
const capitalizeName = (name) => {
  if (!name) return name; // Si es un correo, lo devolvemos tal cual
  return name
    .toLowerCase()
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

export const Income = ({
  item,
  onClick,
}: {
  item: any;
  onClick: () => void;
}) => {
  const [t] = useTranslation("global");
  const [isAction, setIsAction] = useState(false);
  const [select, setSelect] = useRecoilState<any>(SelectTransaction);
  const [visibleModalInfo, setVisibleModalInfo] = useState<boolean>(false);
  console.log(item);

  return (
    <>
      <div
        onClick={() => setVisibleModalInfo(true)}
        className="group cursor-pointer relative w-full h-[56px] px-4 hover:px-3 flex justify-between items-center gap-2 overflow-hidden border-t-[1px] dark:border-t-[var(--strokes-separator-disabled-dark)] transition-all duration-150"
      >
        <div className="w-full min-[1440px]:w-[70%] text-sm h-[36px]  items-center gap-2  inline-flex ">
          <span>
            <svg
              width="36"
              height="36"
              viewBox="0 0 36 36"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 18C0 8.0586 8.0586 0 18 0C27.9414 0 36 8.0586 36 18C36 27.9414 27.9414 36 18 36C8.0586 36 0 27.9414 0 18Z"
                fill="#E2F7D2"
              />
              <path
                d="M14.9473 21.0529L21.0529 14.9473M14.9473 21.0529H18.6121M14.9473 21.0529V17.3881"
                stroke="#2D2E2F"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>

          <div className="w-full  max-h-[30px]  flex-col justify-center gap-[1px] items-start inline-flex ">
            <div className="h-3 justify-start items-center inline-flex">
              <div
                className={`2xl:hidden custom1440:hidden xl:block lg:block tablet:hidden mobile:block font-normal text-[14px] w-[100%]  text-ellipsis overflow-hidden ${
                  item.STATUSORDER === 3 || item.STATUSORDER === 4
                    ? "text-[var(--content-disabled-input)]"
                    : "text-[var(--content-main-black-light)] dark:text-[var(--content-main-black-dark)]"
                }`}
              >
                {capitalizeName(
                  item?.USUARIOVANK === "INTERNO"
                    ? item.NAMEREMISOR?.split(" ")[0]
                    : item?.USUARIOVANK === "EXTERNO" &&
                        t(
                          "Home.LatestTransactions.LatestTransactions.ExternalUser"
                        )?.split(" ")[0]
                )}
              </div>
              <div
                className={`2xl:block custom1440:block xl:hidden lg:hidden tablet:block mobile:hidden w-full font-normal text-[14px]  text-ellipsis overflow-hidden ${
                  item.STATUSORDER === 3 || item.STATUSORDER === 4
                    ? "text-[var(--content-disabled-input)]"
                    : "text-[var(--content-main-black-light)] dark:text-[#FFFFFF]"
                }`}
              >
                {item?.USUARIOVANK === "INTERNO"
                  ? `${capitalizeName(item.NAMEREMISOR?.split(" ")[0])} 
                  ${capitalizeName(item.SURNAMEEMISOR?.split(" ")[0] || "")}`
                  : item?.USUARIOVANK === "EXTERNO" &&
                    t(
                      "Home.LatestTransactions.LatestTransactions.ExternalUser"
                    )}
              </div>
            </div>
            <div className="justify-start items-start  gap-2 inline-flex">
              <div
                className={`text-[11px] font-normal w-full whitespace-nowrap ${
                  item.STATUSORDER === 3 || item.STATUSORDER === 4
                    ? "text-[var(--content-disabled-input)]"
                    : "dark:text-[var(--content-main-black-dark)] text-[var(--content-main-black-light)]"
                } leading-[13.31px]`}
              >
                <span className="xl:inline 2xl:hidden custom1440:hidden custom1366:block  tablet:hidden">
                  {t("Vank.Share.LatestTransactions.VankpayIncome")}
                </span>
                <span className="2xl:inline custom1440:inline xl:hidden custom1366:hidden tablet:inline">
                  {t("Vank.Share.LatestTransactions.VankpayIncomeComplete")}
                </span>
                <span
                  className={`2xl:inline xl:hidden hidden custom1440:inline custom1366:hidden tablet:inline whitespace-nowrap text-[11px] ${
                    item.STATUSORDER === 3 || item.STATUSORDER === 4
                      ? "text-[var(--content-disabled-input)]"
                      : "text-[var(--content-main-gray-light)] dark:text-[var(--content-main-gray-dark)]"
                  }`}
                >
                  {` - ${formtDate(item.GENERATEDDATE)}`}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className=" w-full text-end">
          <div
            className={`h-9 justify-end items-center gap-4 inline-flex ${
              item?.STATUSORDER === 2 ? "" : ""
            } ${
              !isAction ? "translate-x-0" : "translate-x-full"
            } transition-all duration-300`}
          >
            <span
              className={`xl:flex 2xl:flex-shrink-0 xl:flex-shrink-0 whitespace-nowrap text-[14px] font-semibold ${
                item.STATUSORDER === 3 ||
                item.STATUSORDER === 4 ||
                item.STATUSORDER === 5
                  ? "line-through text-[var(--content-disabled-input)]"
                  : "text-[--content-main-black-light] dark:text-[#FFFFFF]"
              }`}
            >
              - {formatBalance(item?.AMOUNT)} {item?.ASSET}
            </span>
            <span
              className={`w-[5rem] rounded-[6px] px-2 flex justify-center items-center h-[24px] text-[11px] text-center leading-[12.1px] font-medium ${
                item.STATUSORDER == 1
                  ? "bg-[#E1FDE4] text-[#1F5928]"
                  : item.STATUSORDER == 2
                  ? "bg-[#FFF3DB] text-[#824215]"
                  : item.STATUSORDER == 3
                  ? "bg-[#EAEAEA] text-[#2D2E2F]"
                  : "bg-[#FDE1E1] text-[#731C1C]"
              }`}
            >
              {item.STATUSORDER == 1 &&
                t("Vank.Share.LatestTransactions.Header.Done")}
              {item.STATUSORDER == 2 &&
                t("Vank.Share.LatestTransactions.Header.Pending")}
              {item.STATUSORDER == 3 &&
                t("Vank.Share.LatestTransactions.Header.canceled")}
              {(item.STATUSORDER == 5 || item.STATUSORDER == 4) &&
                t("Vank.Share.LatestTransactions.Header.Failed")}
            </span>
          </div>
        </div>
        
        <div
          className={`2xl:hidden custom1440:hidden 2xl:group-hover:hidden ml-11 h-[30px] top-1/2 -translate-y-1/2 justify-center group-hover:flex group-hover:opacity-100 flex-col bg-[#FFf] dark:bg-[var(--background-Component-module-dark)]  opacity-0 absolute z-1 ${
            item.STATUSORDER === 3 || item.STATUSORDER === 4
              ? "text-[var(--content-disabled-input)]"
              : "dark:text-[var(--content-main-black-dark)]"
          }`}
        >
          <span className="text-sm leading-[16.94px]">
            {item?.USUARIOVANK === "INTERNO"
              ? `${capitalizeName(item.NAMEREMISOR?.split(" ")[0])} 
                  ${capitalizeName(item.SURNAMEEMISOR?.split(" ")[0] || "")}`
              : item?.USUARIOVANK === "EXTERNO" &&
                t("Home.LatestTransactions.LatestTransactions.ExternalUser")}
          </span>
          <span className="leading-[13.31px]">
            <span
              className={`text-[11px] ${
                item.STATUSORDER === 3 || item.STATUSORDER === 4
                  ? "text-[var(--content-disabled-input)]"
                  : "text-[var(--content-main-black-light)] dark:text-[var(--content-main-black-dark)]"
              }`}
            >
              {t("Vank.Share.LatestTransactions.VankpayIncomeComplete")}
            </span>
            <span
              className={`text-[11px] pr-2 ${
                item.STATUSORDER === 3 || item.STATUSORDER === 4
                  ? "text-[var(--content-disabled-input)]"
                  : "text-[var(--content-main-gray-light)] dark:text-[var(--content-main-gray-dark)]"
              }`}
            >
              {` - ${formtDate(item.GENERATEDDATE)}`}
            </span>
          </span>
        </div>
      </div>

      <CustomModal isOpen={visibleModalInfo}>
        <ModalInfoTransaction
          onClick={() => setVisibleModalInfo(false)}
          data={item}
        />
      </CustomModal>
    </>
  );
};

const Expense = ({ item }) => {
  const servicesOrchestractor = new ServiceOrchestrator();
  const servicesHome = new ServicesHome();
  const [isAction, setIsAction] = useState(false);
  const [isLoadingCancel, setIsLoadingCancel] = useState(false);
  const [select, setSelect] = useRecoilState<any>(SelectTransaction);
  const [t] = useTranslation("global");
  const [visibleModalInfo, setVisibleModalInfo] = useState<boolean>(false);
  const [loadingButtonsCancel, setLoadingButtonsCancel] =
    useState<boolean>(false);
  const [updateBanlance, setUpdateBanlance] = useRecoilState(updateBalnceAtom);

  const handItemTransaction = async (ite: any) => {
    setLoadingButtonsCancel(true);
    try {
      // const data = await serviceOrchestrator.updateOrderVankPayUsers(ite);
      const data = await servicesOrchestractor.updateOrderVankPayUsers(ite);
      let response = data;

      if (response.statusCode == 200) {
        setUpdateBanlance(!updateBanlance);
        setVisibleModalInfo(false);
      }
    } catch (error) {}
  };

  return (
    <>
      <div
        onClick={() => setVisibleModalInfo(true)}
        className="group cursor-pointer border-t-[1px] dark:border-t-[var(--strokes-separator-disabled-dark)]  relative w-full min-h-[56px] px-4 hover:px-3 flex justify-between items-center gap-2 overflow-hidden transition-all duration-150"
      >
        <div className="w-full min-[1440px]:w-[70%] text-sm h-[36px]  items-center gap-2  inline-flex ">
          <span>
            <svg
              width="36"
              height="36"
              viewBox="0 0 36 36"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0 18C0 8.0586 8.0586 0 18 0C27.9414 0 36 8.0586 36 18C36 27.9414 27.9414 36 18 36C8.0586 36 0 27.9414 0 18Z"
                fill="#FDE1E1"
              />
              <path
                d="M21.0529 14.9473L14.9473 21.0529M21.0529 14.9473H17.3881M21.0529 14.9473V18.6121"
                stroke="#2D2E2F"
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>

          <div className="w-full   max-h-[30px]  flex-col justify-center gap-[6px] items-start inline-flex">
            <div
              className={`h-3 justify-start items-center inline-flex ${
                item.STATUSORDER === 3 || item.STATUSORDER === 4
                  ? "text-[var(--content-disabled-input)]"
                  : "text-[var(--content-main-black-light)] dark:text-[var(--content-main-black-dark)]"
              } leading-[16.94px]`}
            >
              <div
                className={`2xl:hidden custom1440:hidden xl:block lg:block tablet:hidden  mobile:block font-normal text-[14px] w-[100%] text-ellipsis overflow-hidden `}
              >
                {capitalizeName(
                  item?.USUARIOVANK === "INTERNO"
                    ? item?.COMPLETENAMERECEPTOR?.split(" ")[0]
                    : item?.USUARIOVANK === "EXTERNO" &&
                        t(
                          "Home.LatestTransactions.LatestTransactions.ExternalUser"
                        )?.split(" ")[0]
                )}
              </div>
              <div
                className={`2xl:block custom1440:inline xl:hidden lg:hidden tablet:block mobile:hidden font-normal text-[14px] w-[100%] ${
                  item.STATUSORDER === 3 ||
                  item.STATUSORDER === 4 ||
                  item.STATUSORDER === 5
                    ? "text-[var(--content-disabled-input)]"
                    : "text-[var(--content-main-black-light)] dark:text-[var(--content-main-black-dark)]"
                } `}
              >
                {item?.USUARIOVANK === "INTERNO"
                  ? `${capitalizeName(
                      item?.COMPLETENAMERECEPTOR?.split(" ")[0]
                    )} 
                     ${capitalizeName(
                       item?.SURNAMERECEPTOR?.split(" ")[0] || ""
                     )}`
                  : item?.USUARIOVANK === "EXTERNO" &&
                    t(
                      "Home.LatestTransactions.LatestTransactions.ExternalUser"
                    )}
              </div>
            </div>

            <div className=" flex justify-start items-start gap-2 w-full whitespace-nowrap ">
              <div
                className={` text-[11px] font-normal w-full ${
                  item.STATUSORDER === 3 ||
                  item.STATUSORDER === 4 ||
                  item.STATUSORDER === 5
                    ? "text-[var(--content-disabled-input)]"
                    : "text-[var(--content-main-black-light)] dark:text-[#C0C0C1]"
                } leading-[13.31px]`}
              >
                <span className="xl:inline 2xl:hidden custom1440:hidden custom1366:inline tablet:hidden  ">
                  {t("Vank.Share.LatestTransactions.SendVankpay")}
                </span>
                <span className="2xl:inline xl:hidden custom1440:inline tablet:inline custom1366:hidden   ">
                  {t("Vank.Share.LatestTransactions.SendVankpayComplete")}
                </span>
                <span
                  className={`text-[11px] 2xl:inline xl:hidden hidden custom1440:inline custom1366:hidden tablet:inline ${
                    item.STATUSORDER === 3 ||
                    item.STATUSORDER === 4 ||
                    item.STATUSORDER === 5
                      ? "text-[var(--content-disabled-input)]"
                      : "text-[var(--content-main-gray-light)] dark:text-[var(--content-main-gray-dark)]"
                  } `}
                >
                  {` - ${formtDate(item.GENERATEDDATE)}`}
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className=" w-full text-end">
          <div
            className={`h-9 justify-end items-center gap-4 inline-flex ${
              item?.STATUSORDER === 2 ? "" : ""
            } ${
              !isAction ? "translate-x-0" : "translate-x-full"
            } transition-all duration-300`}
          >
            <span
              className={`xl:flex 2xl:flex-shrink-0 xl:flex-shrink-0 text-sm font-semibold whitespace-nowrap ${
                item.STATUSORDER === 3 ||
                item.STATUSORDER === 4 ||
                item.STATUSORDER === 5
                  ? "line-through text-[var(--content-disabled-input)]"
                  : "text-[--content-main-black-light] dark:text-[#FFFFFF]"
              }`}
            >
              - {formatBalance(item?.AMOUNT)} {item?.ASSET}
            </span>
            <span
              className={`w-[5rem] rounded-[6px] px-2 flex justify-center items-center h-[24px] text-[11px] text-center leading-[12.1px] font-medium ${
                item.STATUSORDER == 1
                ? "bg-[#E1FDE4] dark:bg-[#E1FDE4] text-[#1F5928] dark:text-[#1F5928]"
                : item.STATUSORDER == 2
                ? "bg-[#FFF3DB] text-[#824215]"
                : item.STATUSORDER == 3
                ? "bg-[#EAEAEA] dark:bg-[#818282] text-[#2D2E2F] dark:text-[#FFFFFF]"
                : "bg-[#FDE1E1] text-[#731C1C]"
              }`}
            >
              {item.STATUSORDER == 1 &&
                t("Vank.Share.LatestTransactions.Header.Done")}
              {item.STATUSORDER == 2 &&
                t("Vank.Share.LatestTransactions.Header.Pending")}
              {item.STATUSORDER == 3 &&
                t("Vank.Share.LatestTransactions.Header.canceled")}
              {(item.STATUSORDER == 5 || item.STATUSORDER == 4) &&
                t("Vank.Share.LatestTransactions.Header.Failed")}
            </span>
          </div>
        </div>

        <div
          className={`2xl:hidden custom1440:hidden 2xl:group-hover:hidden ml-11 h-[30px] top-1/2 -translate-y-1/2 justify-center group-hover:flex group-hover:opacity-100 flex-col bg-[#FFf] dark:bg-[var(--background-Component-module-dark)]  opacity-0 absolute z-1 ${
            item.STATUSORDER === 3 || item.STATUSORDER === 4
              ? "text-[var(--content-disabled-input)]"
              : "dark:text-[var(--content-main-black-dark)]"
          }`}
        >
          <span className="text-sm leading-[16.94px] ">
            {item?.USUARIOVANK === "INTERNO"
              ? `${capitalizeName(item.COMPLETENAMERECEPTOR?.split(" ")[0])} 
                 ${capitalizeName(item.SURNAMERECEPTOR?.split(" ")[0] || "")}`
              : item?.USUARIOVANK === "EXTERNO" &&
                t("Home.LatestTransactions.LatestTransactions.ExternalUser")}
          </span>
          <span className="leading-[13.31px]">
            <span
              className={`text-[11px] ${
                item.STATUSORDER === 3 || item.STATUSORDER === 4
                  ? "text-[var(--content-disabled-input)]"
                  : "text-[var(--content-main-black-light)] dark:text-[var(--content-main-black-dark)]"
              }`}
            >
              {t("Vank.Share.LatestTransactions.SendVankpayComplete")}
            </span>
            <span
              className={`text-[11px] pr-2 ${
                item.STATUSORDER === 3 || item.STATUSORDER === 4
                  ? "text-[var(--content-disabled-input)]"
                  : "text-[var(--content-main-gray-light)] dark:text-[var(--content-main-gray-dark)]"
              }`}
            >
              {` - ${formtDate(item.GENERATEDDATE)}`}
            </span>
          </span>
        </div>
      </div>

      <CustomModal isOpen={visibleModalInfo}>
        <ModalInfoTransaction
          onclickCancel={() => handItemTransaction(item)}
          onClick={() => setVisibleModalInfo(false)}
          data={item}
          loadingButtonsCancel={loadingButtonsCancel}
        />
      </CustomModal>
    </>
  );
};

const TransactionsFiat = ({ item }: { item?: any; }) => {
  const [t, i18n] = useTranslation("global");
  const [modalInfo, setModalInfo] = useState(false);
  const [infoItem, setInfoItem] = useState<null>(null);
  const [modalInfo2, setModalInfo2] = useState(false);

  const handleItemClick = (item) => {
    setInfoItem(item);
    setModalInfo(true);
  };

  const handleItemClick2 = (item) => {
    setInfoItem(item);
    // setInfoItem({ ...item, STATUSORDER: 1 });
    setModalInfo2(true);
  };

  return (
    <>
      <div
        className="w-full h-[56px] px-[16px] py-[10px] flex justify-between items-center group hover:scale-[0.99] border-t-[1px] border-t-[#F1F1F1] dark:border-t-[var(--strokes-separator-disabled-dark)] transition-all duration-300 cursor-pointer"
        onClick={() => handleItemClick(item)}
      >
        <div className="flex items-center gap-[8px]">
          <CustomCircle
            moreStyle={`${
              item.NAME.split(" ")[0] === "Fee"
                ? "bg-[var(--error-bg-light)]"
                : "bg-[var(--error-bg-light)]"
            } min-w-9 h-9 rotate-180`}
            icon={
              item.NAME.split(" ")[0] === "Fee" ? (
                <span
                  style={{
                    color: "var(--content-inner-inner-black-light)",
                  }}
                >
                  {" "}
                  %{" "}
                </span>
              ) : (
                <IconArrowLeft
                  className={` ${
                    item.ACTION === "OUTPUT"
                      ? "-rotate-[50deg]"
                      : "rotate-[136deg]"
                  } w-[15px] h-[15px]`}
                  stroke="var(--content-inner-inner-black-light)"
                />
              )
            }
          />
          <div className="relative w-full h-[32px] flex flex-col justify-start items-start gap-[6px]">
            <span
              className={`2xl:group-hover:opacity-100 custom1440:group-hover:opacity-100  xl:group-hover:opacity-0 lg:group-hover:opacity-0 md:group-hover:opacity-100 group-hover:opacity-0 xl:hidden lg:hidden md:block 2xl:block custom1440:inline  text-sm text-ellipsis overflow-visible whitespace-nowrap
                                  ${
                                    item.STATUSORDER === 3 ||
                                    item.STATUSORDER === 4
                                      ? "text-[var(--content-disabled-input)]"
                                      : "text-[var(--content-main-black-light)] dark:text-[var(--content-main-black-dark)]"
                                  } leading-none`}
            >
              {item.NAME.split(" ")[0] === "Fee"
                ? t(
                    "Vank.Transaction.Money.Tables.TableTransactionFiat.text4"
                  ) + ` ${item.FEE * 100}%`
                : capitalizeName(
                    ` ${item.NAME.split(" ")[0]} ${
                      item.SURNAME ? item.SURNAME.split(" ")[0] : ""
                    }`
                  )}
            </span>
            <span
              className={`2xl:group-hover:opacity-100 custom1440:group-hover:opacity-100  xl:group-hover:opacity-0 lg:group-hover:opacity-0 md:group-hover:opacity-100 group-hover:opacity-0 2xl:hidden custom1440:hidden  xl:block lg:block md:hidden  text-sm text-ellipsis  overflow-visible  ${
                item.STATUSORDER === 3 || item.STATUSORDER === 4
                  ? "text-[var(--content-disabled-input)]"
                  : "text-[var(--content-main-black-light)] dark:text-[var(--content-main-white-light)]"
              } leading-none`}
            >
              {item.NAME.split(" ")[0] === "Fee"
                ? t(
                    "Vank.Transaction.Money.Tables.TableTransactionFiat.text4"
                  ) + ` ${item.FEE * 100}%`
                : `${item.NAME.split(" ")[0]}`}
            </span>
            <div
              className={`2xl:group-hover:opacity-100 custom1440:group-hover:opacity-100  xl:group-hover:opacity-0 lg:group-hover:opacity-0 md:group-hover:opacity-100 group-hover:opacity-0 whitespace-nowrap text-[11px] w-full flex items-center gap-1 ${
                item.STATUSORDER === 3 || item.STATUSORDER === 4
                  ? "text-[var(--content-disabled-input)]"
                  : "text-[var(--content-main-black-light)] dark:text-[var(--content-main-black-dark)]"
              } leading-none`}
            >
              <span className="2xl:hidden custom1440:hidden  xl:block lg:block md:hidden">
                {t(
                  "Vank.Transaction.Money.Tables.TableTransactionFiat.SendFiat"
                )}{" "}
              </span>
              <span className="xl:hidden lg:hidden md:block 2xl:block custom1440:inline">
                {t(
                  "Vank.Transaction.Money.Tables.TableTransactionFiat.SendFiatComplete"
                )}{" "}
              </span>
              <span
                className={`text-[11px] ${
                  item.STATUSORDER === 3 || item.STATUSORDER === 4
                    ? "text-[var(--content-disabled-input)]"
                    : "text-[var(--content-main-gray-light)] dark:text-[var(--content-main-gray-dark)]"
                }`}
              >
                {`- ${formtDate(item.GENERATEDDATE)}`}
              </span>
            </div>

            <div
              className={`absolute h-[32px] 2xl:group-hover:opacity-0 custom1440:group-hover:opacity-0  xl:group-hover:opacity-100 lg:group-hover:opacity-100 md:group-hover:opacity-0 group-hover:opacity-100 flex opacity-0 flex-col justify-start items-start gap-[6px]  bg-[var(--bacground-component-module-light)] dark:bg-[var(--bacground-component-module-dar)] transition-opacity duration-200 z-10 ease-in-out`}
            >
              <span
                className={`text-sm
                                    ${
                                      item.STATUSORDER === 3 ||
                                      item.STATUSORDER === 4
                                        ? "text-[var(--content-disabled-input)]"
                                        : "text-[var(--content-main-black-light)] dark:text-[var(--content-main-black-dark)]"
                                    } leading-none`}
              >
                {item.NAME.split(" ")[0] === "Fee"
                  ? t(
                      "Vank.Transaction.Money.Tables.TableTransactionFiat.text4"
                    ) + ` ${item.FEE * 100}%`
                  : capitalizeName(
                      ` ${item.NAME.split(" ")[0]} ${
                        item.SURNAME ? item.SURNAME.split(" ")[0] : ""
                      }`
                    )}
              </span>
              <div
                className={`whitespace-nowrap text-[12px] w-full ${
                  item.STATUSORDER === 3 || item.STATUSORDER === 4
                    ? "text-[var(--content-disabled-input)]"
                    : "text-[var(--content-main-black-light)] dark:text-[var(--content-main-black-dark)]"
                } leading-none`}
              >
                <span className="">
                  {t(
                    "Vank.Transaction.Money.Tables.TableTransactionFiat.SendFiatComplete"
                  )}
                </span>
                <span
                  className={`text-[11px] ${
                    item.STATUSORDER === 3 || item.STATUSORDER === 4
                      ? "text-[var(--content-disabled-input)]"
                      : "text-[var(--content-main-gray-light)] dark:text-[var(--content-main-gray-dark)]"
                  }`}
                >
                  {` - ${formtDate(item.GENERATEDDATE)}`}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="flex items-center gap-[8px]">
          <div
            className={`xl:flex 2xl:flex-shrink-0 xl:flex-shrink-0 text-sm  font-semibold whitespace-nowrap ${
              item.STATUSORDER === 3 || item.STATUSORDER === 4
                ? "line-through text-[var(--content-disabled-input)]"
                : "text-[var(--content-main-black-light)] dark:text-[#FFFFFF]"
            }`}
          >
            - {formatBalance(item?.AMOUNT)} {item?.ASSETNAME}
          </div>
          <span
            className={`w-[5rem] rounded-[6px] px-2 flex justify-center items-center h-[24px] text-[11px] text-center leading-[12.1px] font-medium ${
              item.STATUSORDER == 1
                ? "bg-[#E9FFD9] text-[#1F5928]"
                : item.STATUSORDER == 2
                ? "bg-[#FFF3DB] text-[#824215]"
                : item.STATUSORDER == 3
                ? "bg-[#FDE1E1] text-[#731C1C]"
                : "bg-[#FDE1E1] text-[#731C1C]"
            }`}
          >
            {item.STATUSORDER == 1 &&
              t("Vank.Share.LatestTransactions.Header.Done")}
            {item.STATUSORDER == 2 &&
              t("Vank.Share.LatestTransactions.Header.Pending")}
            {item.STATUSORDER == 3 &&
              t("Vank.Share.LatestTransactions.Header.Failed")}
            {(item.STATUSORDER == 5 || item.STATUSORDER == 4) &&
              t("Vank.Share.LatestTransactions.Header.Failed")}
          </span>
        </div>
      </div>
      {item.STATUSORDER === 1 && ( <div className="w-full h-px bg-[#F1F1F1] dark:bg-[var(--strokes-separator-disabled-dark)]" />)}
      <div
        onClick={() => handleItemClick2(item)}
        className={`${
          item.STATUSORDER != 1
            ? "hidden"
            : "w-full h-[56px] px-[16px] py-[10px] flex justify-between items-center group hover:scale-[0.99] transition-all duration-300 cursor-pointer"
        }`}
      >
        <div className="flex items-center gap-[8px]">
          <span>
            <svg
              className=" stroke-[#2D2E2F] dark:stroke-[#fff]"
              xmlns="http://www.w3.org/2000/svg"
              width="36"
              height="36"
              viewBox="0 0 36 36"
              fill="none"
            >
              <rect
                x="1"
                y="1"
                width="34"
                height="34"
                rx="17"
                stroke="#FDE1E1"
                strokeWidth="2"
              />
              <path
                d="M22.3197 13.6797L13.6797 22.3197M20.8797 22.3197C20.0844 22.3197 19.4397 21.675 19.4397 20.8797C19.4397 20.0844 20.0844 19.4397 20.8797 19.4397C21.675 19.4397 22.3197 20.0844 22.3197 20.8797C22.3197 21.675 21.675 22.3197 20.8797 22.3197ZM15.1197 16.5597C14.3244 16.5597 13.6797 15.915 13.6797 15.1197C13.6797 14.3244 14.3244 13.6797 15.1197 13.6797C15.915 13.6797 16.5597 14.3244 16.5597 15.1197C16.5597 15.915 15.915 16.5597 15.1197 16.5597Z"
                stroke=""
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </span>
          <div className="relative w-full h-[32px] flex flex-col justify-start items-start gap-[6px]">
            <span
              className={`2xl:group-hover:opacity-100 custom1440:group-hover:opacity-100  xl:group-hover:opacity-0 lg:group-hover:opacity-0 md:group-hover:opacity-100 group-hover:opacity-0 xl:hidden lg:hidden md:block 2xl:block custom1440:inline text-sm text-ellipsis w-full  overflow-visible whitespace-nowrap ${
                item.STATUSORDER === 3 || item.STATUSORDER === 4
                  ? "text-[var(--content-disabled-input)]"
                  : "text-[var(--content-main-black-light)] dark:text-[var(--content-main-black-dark)] "
              } leading-none`}
            >
              {t("Vank.Transaction.Money.Tables.TableTransactionFiat.text4")}
            </span>
            <span
              className={`2xl:group-hover:opacity-100 custom1440:group-hover:opacity-100  xl:group-hover:opacity-0 lg:group-hover:opacity-0 md:group-hover:opacity-100 group-hover:opacity-0 2xl:hidden custom1440:hidden  xl:block lg:block md:hidden text-sm text-ellipsis w-full  overflow-visible whitespace-nowrap ${
                item.STATUSORDER === 3 || item.STATUSORDER === 4
                  ? "text-[var(--content-disabled-input)]"
                  : "text-[var(--content-main-black-light)] dark:text-[var(--content-main-black-dark)] "
              } leading-none`}
            >
              {t("Vank.Transaction.Money.Tables.TableTransactionFiat.text5")}
            </span>
            <span
              className={`2xl:group-hover:opacity-100 custom1440:group-hover:opacity-100  xl:group-hover:opacity-0 lg:group-hover:opacity-0 md:group-hover:opacity-100 group-hover:opacity-0 text-[11px] ${
                item.STATUSORDER === 3 || item.STATUSORDER === 4
                  ? "text-[var(--content-disabled-input)]"
                  : "text-[var(--content-main-gray-light)] dark:text-[var(--content-main-gray-dark)]"
              } leading-none`}
            >
              {`${formtDate(item.GENERATEDDATE)}`}
            </span>
            <div
              className={`absolute h-[32px] 2xl:group-hover:opacity-0 custom1440:group-hover:opacity-0  xl:group-hover:opacity-100 lg:group-hover:opacity-100 md:group-hover:opacity-0 group-hover:opacity-100 flex opacity-0 flex-col justify-start items-start px-1 gap-[6px]  bg-[var(--bacground-component-module-light)] dark:bg-[var(--bacground-component-module-dar)] transition-opacity duration-200 ease-in-out`}
            >
              <span
                className={`text-sm text-ellipsis w-full  overflow-visible whitespace-nowrap ${
                  item.STATUSORDER === 3 || item.STATUSORDER === 4
                    ? "text-[var(--content-disabled-input)]"
                    : "text-[var(--content-main-black-light)] dark:text-[var(--content-main-black-dark)] "
                } leading-none`}
              >
                {t("Vank.Transaction.Money.Tables.TableTransactionFiat.text4")}
              </span>
              <span
                className={`text-[11px] ${
                  item.STATUSORDER === 3 || item.STATUSORDER === 4
                    ? "text-[var(--content-disabled-input)]"
                    : "text-[var(--content-main-gray-light)] dark:text-[var(--content-main-gray-dark)]"
                } leading-none`}
              >
                {`${formtDate(item.GENERATEDDATE)}`}
              </span>
            </div>
          </div>
        </div>
        <div className="flex items-center gap-[8px]">
          <p
            className={`flex gap-2 text-sm  item-table-amount whitespace-nowrap font-semibold ${
              item.STATUSORDER === 3 || item.STATUSORDER === 4
                ? "line-through text-[var(--content-disabled-input)]"
                : "text-[var(--content-main-black-light)] dark:text-[#FFFFFF]"
            }`}
          >
            {formatBalance(item.DISACOUNT)} {item.ASSETNAME}{" "}
            <span className="text-[#818282]"> ({item.FEE}%)</span>
          </p>
          <span
            className={`w-[5rem] rounded-[6px] px-2 flex justify-center items-center h-[24px] text-[11px] text-center leading-[12.1px] font-medium ${
              item.STATUSORDER == 1
                ? "bg-[#E9FFD9] text-[#1F5928]"
                : item.STATUSORDER == 2
                ? "bg-[#FFF3DB] text-[#824215]"
                : item.STATUSORDER == 3
                ? "bg-[#FDE1E1] text-[#731C1C]"
                : "bg-[#FDE1E1] text-[#731C1C]"
            }`}
          >
            {item.STATUSORDER == 1 &&
              t("Vank.Share.LatestTransactions.Header.Done")}
            {item.STATUSORDER == 2 &&
              t("Vank.Share.LatestTransactions.Header.Pending")}
            {item.STATUSORDER == 3 &&
              t("Vank.Share.LatestTransactions.Header.Failed")}
            {(item.STATUSORDER == 5 || item.STATUSORDER == 4) &&
              t("Vank.Share.LatestTransactions.Header.Failed")}
          </span>
        </div>
      </div>

      {modalInfo && (
        <CustomModal isOpen={modalInfo}>
          <ModalApprovedShipping
            isMoneyModalOpen={false}
            data={infoItem}
            onClose={() => setModalInfo(false)}
          />
        </CustomModal>
      )}

      <CustomModal isOpen={modalInfo2}>
        <ModalFeeTransaction
          data={infoItem}
          onClose={() => setModalInfo2(false)}
          isMoneyModalOpen={false}
        />
      </CustomModal>
    </>
  );
};

const Conversion = ({
  item,
  handleSelect,
}: {
  item: any;
  handleSelect: any;
}) => {
  const [isAction, setIsAction] = useState(false);
  const [t] = useTranslation("global");
  // const [select, setSelect] = useRecoilState<any>(SelectTransaction)

  return (
    <div
      className="relative w-full hover:scale-[0.98] min-h-[56px] px-4 flex justify-between items-center gap-2 overflow-hidden"
      onClick={() => handleSelect(item)}
    >
      <div className="w-[256px] h-[36px] justify-center items-center gap-2  inline-flex">
        <div className="w-[36px] min-h-[36px] justify-center items-center flex bg-[#FFE6C0] rounded-[50px]">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
          >
            <path
              d="M7 14.9143L10 18V6"
              stroke="var(--content-inner-inner-black-light)"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M17 9.08571L14 6V18"
              stroke="var(--content-inner-inner-black-light)"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>

        <div className="w-[212px] max-h-[30px] flex-col justify-center gap-[1px] items-start inline-flex">
          <div className="h-3 justify-start items-center inline-flex">
            <div className="text-[var(--content-main-black-light)] text-base font-normal ">
              {t("Home.LatestTransactions.LatestTransactions.Conversion")}
            </div>
          </div>
          <div className="justify-start items-start gap-2 inline-flex">
            <div className="text-[var(--content-main-black-light)] text-xs font-normal ">
              {t("Home.LatestTransactions.LatestTransactions.Ordermade")}
            </div>
            <div className="text-[var(--content-main-gray-light)] text-xs font-normal ">
              {formtDate(item?.GENERATEDDATE)}
            </div>
          </div>
        </div>
      </div>

      <div
        className={`w-full min-[1440px]:min-w-[108px] h-9 justify-end items-center gap-4 inline-flex ${
          item?.STATEPROCESSINVEST === 2 ? "" : "hidden"
        } ${
          !isAction ? "translate-x-0" : "translate-x-full"
        } transition-all duration-300`}
      >
        {/* <span className='text-[--color-darkest-grey] text-base font-semibold'>{item?.AMOUNT} {item?.ASSET}</span> */}
        <div className="w-[76px] h-[24px] p-2 bg-[var(--pending-pending-chips-light)] rounded-3xl justify-center items-center flex">
          <div className="text-center text-[var(--content-main-white-dark)] text-xs font-bold">
            Pen{t("Home.LatestTransactions.LatestTransactions.earring")}diente
          </div>
        </div>
        <button className="w-4 h-4" onClick={() => setIsAction(true)}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
          >
            <path
              d="M13 13L3 3M3 13L13 3"
              stroke="var(--content-inner-inner-black-light)"
              strokeWidth="1.6"
              strokeLinecap="round"
            />
          </svg>
        </button>
      </div>

      <div
        className={`min-[1440px]:min-w-[211px] h-[36px] flex justify-end items-center gap-4 ${
          item?.STATEPROCESSINVEST === 1 ? "" : "hidden"
        } `}
      >
        {/* <span className='text-[--color-darkest-grey] text-base font-semibold'>{formatBalance(1000)} {item?.ASSET}</span> */}
        <div className="w-[55px] h-6 p-2 bg-[var(--success-chip-light)] rounded-[24px] justify-center items-center inline-flex">
          <div className="text-center text-[var(--content-inner-inner-white-light)] text-xs font-bold">
            {t("Home.LatestTransactions.LatestTransactions.Made")}
          </div>
        </div>
      </div>

      <div
        className={`min-[1440px]:min-w-[211px] h-[36px] flex justify-end items-center gap-4 ${
          item?.STATEPROCESSINVEST === 3 ? "" : "hidden"
        } `}
      >
        <span className="text-[var(--content-main-black-light)] text-base font-semibold">
          {item?.AMOUNT} {item?.ASSET}
        </span>
        <div className="w-[55px] h-6 p-2 bg-[#ffa4a4] rounded-[24px] justify-center items-center inline-flex">
          <div className="text-center text-[var(--content-inner-inner-white-light)] text-xs font-bold">
            {t("Home.LatestTransactions.LatestTransactions.Cancel")}
          </div>
        </div>
      </div>

      <div
        className={`absolute right-0 w-[228px] h-[56px] py-4 bg-[var(--bacground-component-module-light)] flex justify-center items-center rounded-l-[8px] shadow ${
          isAction ? "translate-x-0" : "translate-x-full"
        } transition-all duration-300`}
      >
        <div className="w-[163px] h-[24px] flex justify-center items-center gap-3">
          <span className="w-[87px] text-[12px] text-[var(--content-main-black-light)]">
            {t("Home.LatestTransactions.LatestTransactions.Areyousure")}
          </span>
          <div className="w-16 h-6 justify-center items-start gap-1 inline-flex">
            <div className="w-[27px] h-[24px] p-2 rounded-[24px] border border-[var(--content-main-gray-light)] justify-center items-center flex cursor-pointer">
              <div className="text-center text-[var(--content-main-gray-light)] text-xs font-normal">
                {t("Home.LatestTransactions.LatestTransactions.yes")}
              </div>
            </div>
            <button
              className="w-[33px] h-[24px] p-2 rounded-[24px] border border-[var(--content-main-gray-light)] justify-center items-center flex cursor-pointer"
              onClick={() => setIsAction(false)}
            >
              <div className="text-center text-[var(--content-main-gray-light)] text-xs font-normal">
                {t("Home.LatestTransactions.LatestTransactions.No")}
              </div>
            </button>
          </div>
        </div>
      </div>

      <div className="absolute bottom-0 -right-2 w-[514px] h-px pl-16 flex-col justify-center items-end gap-2.5 inline-flex">
        <div className="w-full h-px bg-gray-200" />
      </div>
    </div>
  );
};

const TransactionBatch = ({ item }) => {
  const [isAction, setIsAction] = useState(false);
  const [select, setSelect] = useRecoilState<any>(SelectTransaction);
  const [t] = useTranslation("global");

  return (
    <div className="relative w-full  min-h-[56px] px-4 flex justify-between items-center gap-2 overflow-hidden">
      <div className="w-[175px] h-[36px] justify-center items-center gap-2  inline-flex">
        <div className="w-full min-[1440px]:min-w-[36px] min-h-[36px] justify-center items-center flex bg-[var(--error-bg-light)] rounded-[50px]">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="15"
            viewBox="0 0 14 15"
            fill="none"
          >
            <path
              d="M13.0367 1.34631L3.05675 11.3463M13.0367 1.34631H9.05675M13.0367 1.34631V5.34631M13.0567 10.218L11.5589 8.71226M13.0567 10.218L12.0592 12.2115M13.0567 10.218C13.0567 10.218 10.5984 10.6021 9.05674 11.218C7.1909 11.9635 5.55696 13.6537 5.55696 13.6537M5.24619 1.55638L6.5632 3.22259M5.24619 1.55638L3.14821 2.30983M5.24619 1.55638C5.24619 1.55638 4.57262 3.95159 3.77771 5.40911C2.81567 7.17308 0.943207 8.59447 0.943207 8.59447"
              stroke="var(--content-inner-inner-black-light)"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
        </div>

        <div className="w-[212px] max-h-[30px] flex-col justify-center gap-[1px] items-start inline-flex">
          <div className="h-3 justify-start items-center inline-flex">
            <div className="text-[var(--content-main-black-light)] text-base font-normal ">
              {item?.name}
            </div>
          </div>
          <div className="justify-start items-start gap-2 inline-flex">
            <div className="text-[var(--content-main-gray-light)] text-xs font-normal ">
              {item?.date}
            </div>
          </div>
        </div>
      </div>

      <div
        className={`w-full min-[1440px]:min-w-[108px] h-9 justify-end items-center gap-4 inline-flex ${
          item?.status !== 0 && "hidden"
        } ${
          !isAction ? "translate-x-0" : "translate-x-full"
        } transition-all duration-300`}
      >
        <span className="text-[var(--content-main-black-light)] text-base font-semibold">
          {item?.free} {item?.asset}
        </span>
        <div className="w-[76px] h-[24px] p-2 bg-[var(--pending-pending-chips-light)] rounded-3xl justify-center items-center flex">
          <div className="text-center text-[var(--content-inner-inner-white-light)] text-xs font-bold">
            {t("Home.LatestTransactions.LatestTransactions.earring")}
          </div>
        </div>
        <button className="w-4 h-4" onClick={() => setIsAction(true)}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
          >
            <path
              d="M13 13L3 3M3 13L13 3"
              stroke="var(--content-inner-inner-black-light)"
              strokeWidth="1.6"
              strokeLinecap="round"
            />
          </svg>
        </button>
      </div>

      <div
        className={`min-[1440px]:min-w-[211px] h-[36px] flex justify-center items-center gap-4 ${
          item?.status === 0 && "hidden"
        } `}
      >
        <span className="text-[var(--content-main-black-light)] text-base font-semibold">
          {item?.free} {item?.asset}
        </span>
        <div className="w-[55px] h-6 p-2 bg-[var(--success-chip-light)] rounded-[24px] justify-center items-center inline-flex">
          <div className="text-center text-white text-xs font-bold">
            {t("Home.LatestTransactions.LatestTransactions.Made")}
          </div>
        </div>
      </div>

      <div
        className={`absolute right-0 w-[228px] h-[56px] py-4 bg-[var(--bacground-component-module-light)] flex justify-center items-center rounded-l-[8px] shadow ${
          isAction ? "translate-x-0" : "translate-x-full"
        } transition-all duration-300`}
      >
        <div className="w-[163px] h-[24px] flex justify-center items-center gap-3">
          <span className="w-[87px] text-[12px] text-[var(--content-main-black-light)]">
            {t("Home.LatestTransactions.LatestTransactions.Areyousure")}
          </span>
          <div className="w-16 h-6 justify-center items-start gap-1 inline-flex">
            <div className="w-[27px] h-[24px] p-2 rounded-[24px] border border-[var(--content-main-gray-light)] justify-center items-center flex cursor-pointer">
              <div className="text-center text-[var(--content-main-gray-light)] text-xs font-normal">
                {t("Home.LatestTransactions.LatestTransactions.yes")}
              </div>
            </div>
            <button
              className="w-[33px] h-[24px] p-2 rounded-[24px] border border-[var(--content-main-gray-light)] justify-center items-center flex cursor-pointer"
              onClick={() => setIsAction(false)}
            >
              <div className="text-center text-[var(--content-main-gray-light)] text-xs font-normal">
                {t("Home.LatestTransactions.LatestTransactions.No")}
              </div>
            </button>
          </div>
        </div>
      </div>

      <div className="absolute bottom-0 -right-2 w-[514px] h-px pl-16 flex-col justify-center items-end gap-2.5 inline-flex">
        <div className="w-full h-px bg-gray-200" />
      </div>
    </div>
  );
};

const LatestTransactions = ({
  listTransactions,
  isLoadingHistory,
  className,
}: {
  listTransactions: any;
  isLoadingHistory: any;
  className?: string;
}) => {
  const [t, i18n] = useTranslation("global");

  const [isConversion, setIsConversion] = useState(false);
  const [isOpenModalMobile, setIsOpenModalMobile] = useState(false);
  const [select, setSelect] = useRecoilState<any>(SelectTransaction);
  const [counter, setCounter] = useRecoilState(counterSelector);
  const [users, setUsers] = useRecoilState<any>(ProfileUser);

  const { theme, toggleDarkMode } = useTheme(); // Usa el contexto

  const step6Ref = useRef<HTMLDivElement>(null);
  const step6Refmobile = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const scrollToStep = (stepRef: React.RefObject<HTMLElement>) => {
      if (stepRef.current) {
        stepRef.current.scrollIntoView({
          behavior: "smooth",
          block: "center", // Mantén 'center' si prefieres un comportamiento estándar
          inline: "nearest",
        });
      }
    };

    if (counter === 5) {
      scrollToStep(step6Ref); // Intenta hacer scroll a la referencia de escritorio primero
      scrollToStep(step6Refmobile); // Intenta hacer scroll a la referencia de escritorio primero
    }
  }, [counter]);

  const OnboardingData = [
    {
      id: 1,
      change: "+1.02%",
      rate: "$1.12",
      name: "1200 USD ⭢ 1380 USD",
      price: "",
      date: "Abr 12, 2024 01:46 pm",
      type: "convert",
      STATEPROCESSINVEST: 1,
    },
    {
      id: 2,
      change: "-0.56%",
      rate: "$1.12",
      name: "Conversión",
      price: "",
      date: "Abr 12, 2024 01:46 pm",
      type: "convert",
      STATEPROCESSINVEST: 2,
    },
    {
      id: 3,
      change: "-0.56%",
      rate: "$1.12",
      name: "Conversión",
      price: "",
      date: "Abr 12, 2024 01:46 pm",
      type: "convert",
      STATEPROCESSINVEST: 1,
    },
    //  -------------
    {
      id: 4,
      change: "-0.56%",
      rate: "$1.12",
      name: "Katlin Rogers",
      price: "+ $1,420.72 USD ",
      date: "Abr 12, 2024 01:46 pm",
      type: "Income",
      STATEPROCESSINVEST: 1,
    },
    {
      id: 5,
      change: "-0.56%",
      rate: "$1.12",
      name: "Recepción",
      price: "- $1,420.72 USD",
      date: "Abr 12, 2024 01:46 pm",
      type: "Income",
      STATEPROCESSINVEST: 1,
    },
    {
      id: 6,
      change: "-0.56%",
      rate: "$1.12",
      name: "Recepción",
      price: "- $234,000 COP",
      date: "Abr 12, 2024 01:46 pm",
      type: "Income",
      STATEPROCESSINVEST: 1,
    },
  ];

  // const _className = className ? className : 'relative w-full min-[1440px]:min-w-[530px] h-[398px] bg-white rounded-2xl flex-col justify-start items-start inline-flex'
  const _className = `${
    className ? className : ""
  } relative bg-[var(--bacground-component-module-light)] dark:bg-[var(--background-component-module-dark)] flex-col justify-start items-start inline-flex 2xl:p-0 xl:p-0 lg:p-0 md:p-0 sm:p-0 mobile-375:p-0 mobile:p-0 overflow-hidden ${
    counter === 5 && "z-50"
  }`;

  const handleSelect = (item) => {
    setSelect((selec?: any) => {
      const selePayload = {
        ...selec,
        transaction: item,
      };
      return selePayload;
    });
    setIsConversion(true);
  };

  useEffect(() => {}, [listTransactions?.lengt > 0]);

  const newF = () => {};

  const handleClickOpenModalTransaction = () => {
    setIsOpenModalMobile(!isOpenModalMobile);
  };

  return (
    <div
      className={_className}
      style={{
        pointerEvents: users?.STATUSINCODE === 2 ? "none" : "auto",
      }}
    >
      <div className="w-full min-h-[60px] rounded-[16px] 2xl:p-4 xl:p-4 lg:p-4 md:p-4 md:top-0 sm:p-4 mobile:p-0 2xl:gap-6 xl:px-[16px] lg:px-[16px] tablet:px-[16px] mobile:px-[16px] xl:gap-6 lg:gap-6 tablet:gap-6 mobile-375:gap-7 mobile:gap-1 flex justify-between items-center  2xl:border-b xl:border-b lg:border-b md:border-b sm:border-b mobile:border-none 2xl:border-[var(--strokes-separator-separator-light)]">
        <span className="text-[var(--content-main-black-light)] dark:text-[#FFFFFF] xl:text-base lg:text-base tablet:text-base mobile:text-[14px] 2xl:gap-6 xl:px-[0px] lg:px-[0px] tablet:px-[0px] mobile:px-[0px] xl:font-medium lg:font-medium tablet:font-medium mobile:font-normal ">
          {t("Vank.Share.LatestTransactions.Header.Title")}
        </span>
        {listTransactions?.length !== 0 && (
          <motion.div
            className=""
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: 0.9 }}
          >
            <span className="text-[var(--content-main-gray-light)] text-sm font-medium underline cursor-pointer 2xl:block xl:block lg:block md:block sm:block mobile:hidden ">
              {t("Vank.Share.LatestTransactions.Header.ViewMore")}
            </span>
            {/* // mobile */}
            <span
              className="text-[var(--content-main-gray-light)] 2xl:text-[14px] xl:text-[14px] lg:text-[14px] tablet:text-[14px] mobile:text-[10px] font-medium underline cursor-pointer 2xl:hidden xl:hidden lg:hidden md:hidden sm:hidden mobile:block"
              onClick={handleClickOpenModalTransaction}
            >
              {t("Vank.Share.LatestTransactions.Header.ViewMore")}
            </span>
          </motion.div>
        )}
      </div>
      <div
        className={`w-full h-[518px] 2xl:flex xl:flex lg:flex md:flex sm:flex mobile:hidden flex-col justify-start items-center ${
          users?.STATUSINCODE === 2 ? "overflow-hidden" : "overflow-y-auto "
        }`}
      >
        <div className="w-full h-full">
          {users?.STATUSINCODE === 2 ? (
            OnboardingData.map((item, index) => (
              <div key={index}>
              <div className="relative w-full  min-h-[56px] px-4 flex justify-between items-center gap-2 overflow-hidden">
                <div className="w-[256px] h-[36px] justify-center items-center gap-2  inline-flex">
                  <div
                    className={`w-[36px] min-h-[36px] justify-center items-center flex ${
                      item.type === "convert"
                        ? "bg-[var(--pending-pending-bg-light)]"
                        : "bg-[var(--success-backgound-light)]"
                    } rounded-[50px]`}
                  >
                    {item.type === "convert" ? (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                      >
                        <path
                          d="M5.40015 11.0982L7.56015 13.3199V4.67993M12.6001 6.90165L10.4401 4.67993V13.3199"
                          stroke="var(--content-inner-inner-black-light)"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                      >
                        <path
                          d="M5.94727 12.0527L12.0529 5.94714M5.94727 12.0527H9.61207M5.94727 12.0527V8.38794"
                          stroke="var(--content-inner-inner-black-light)"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    )}
                  </div>

                  <div className="w-[212px] max-h-[30px] flex-col justify-center gap-[1px] items-start inline-flex">
                    <div className="h-3 justify-start items-center inline-flex">
                      <div className="text-[var(--content-inner-inner-black-light)] dark:text-[#FFFFFF] text-base font-normal ">
                        {item.name}
                      </div>
                    </div>
                    <div className="justify-start items-start gap-2 inline-flex">
                      {index === 1 && (
                        <span className="text-[var(--content-main-black-light)] dark:text-[#C0C0C1] text-xs font-normal ">
                          {t(
                            "Home.LatestTransactions.LatestTransactions.Ordermade"
                          )}
                          :
                        </span>
                      )}
                      <div className="text-[var(--content-main-gray-light)] dark:text-[#C0C0C1] text-xs font-normal ">
                        {item.date}
                      </div>
                    </div>
                  </div>
                </div>

                <div
                  className={`w-full min-[1440px]:min-w-[108px] h-9 justify-end items-center gap-4 inline-flex ${
                    item?.STATEPROCESSINVEST === 2 ? "" : "hidden"
                  } translate-x-0 transition-all duration-300`}
                >
                  {/* <span className='text-[--color-darkest-grey] text-base font-semibold'>{item?.AMOUNT} {item?.ASSET}</span> */}
                  <div className="w-[76px] h-[24px] p-2 bg-[var(--pending-pending-chips-light)] rounded-3xl justify-center items-center flex">
                    <div className="text-center text-[var(--content-main-white-light)] text-xs font-bold">
                      {t("Home.LatestTransactions.LatestTransactions.earring")}
                    </div>
                  </div>
                </div>

                <div
                  className={`min-[1440px]:min-w-[211px] h-[36px] flex justify-end items-center gap-4 ${
                    item?.STATEPROCESSINVEST === 1 ? "" : "hidden"
                  } `}
                >
                  <span className="text-[var(--content-main-black-light)] dark:text-[#FFFFFF] text-base font-semibold">
                    {item?.price}
                  </span>
                  <div className="w-[55px] h-6 p-2 bg-[var(--success-chip-light)] rounded-[24px] justify-center items-center inline-flex">
                    <div className="text-center text-[var(--content-main-white-light)] text-xs font-bold">
                      {t("Home.LatestTransactions.LatestTransactions.Made")}
                    </div>
                  </div>
                </div>

                <div
                  className={`min-[1440px]:min-w-[211px] h-[36px] flex justify-end items-center gap-4 ${
                    item?.STATEPROCESSINVEST === 3 ? "" : "hidden"
                  } `}
                >
                  <span className="text-[var(--content-main-black-light)] dark:text-[#FFFFFF] text-base font-semibold">
                    + $1,420.72 USD
                  </span>
                  <div className="w-[55px] h-6 p-2 bg-[#ffa4a4] rounded-[24px] justify-center items-center inline-flex">
                    <div className="text-center text-[var(--content-main-white-light)] text-xs font-bold">
                      {t("Home.LatestTransactions.LatestTransactions.Cancel")}
                    </div>
                  </div>
                </div>

                {/* w-[514px] */}
                <div className="absolute bottom-0 -right-2 w-full h-px pl-16 flex-col justify-center items-end gap-2.5 inline-flex">
                  <div className="w-full h-px bg-[var(--strokes-separator-separator-light)] dark:bg-[#2D2E2F]" />
                </div>
              </div>
              </div>
            ))
          ) : !isLoadingHistory ? (
            <motion.div
              className=""
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              transition={{ duration: 0.9 }}
            >
              {listTransactions?.length > 0 ? (
                listTransactions.map((transaction, index) => {
                  
                  switch (transaction?.ACTION) {
                    case "INPUT":
                      return (
                        <Income
                          onClick={() => newF()}
                          key={transaction.id || index}
                          item={transaction}
                        />
                      );
                    case "OUTPUT":
                      return <Expense key={transaction.id || index} item={transaction} />;
                    case "PAYMENTS":
                      return (
                        <TransactionsFiat key={transaction.id || index} item={transaction} />
                      );
                    // case "CONVERT":
                    // return <Conversion key={index} item={transaction} handleSelect={handleSelect} />;
                    case "batch":
                      return (
                        <TransactionBatch key={transaction.id || index} item={transaction} />
                      );
                    default:
                      return null;
                  }
                })
              ) : (
                <div className="w-full h-full flex flex-col justify-center items-center lg:p-0 mobile:p-2 border-t-[1px] border-[var(--strokes-separator-separator-light)] dark:border-[#2D2E2F] lg:flex  ">
                  {/* <span className="text-[#818282] dark:text-[#C0C0C1] lg:hidden mobile:flex">
                    {t("Home.LatestTransactions.LatestTransactions.Text1")}
                  </span> */}
                  <div className="w-[80%] 2xl:mt-[20%] xl:mt-[20%] tablet:mt-[25%]  h-full flex flex-col justify-center items-center  rounded-2xl text-center text-[#818282] dark:text-[#C0C0C1] lg:flex ">
                    <IconNoMovement />
                    {t("Home.LatestTransactions.LatestTransactions.Text1")}
                  </div>
                </div>
              )}
            </motion.div>
          ) : (
            <div className="w-full h-full flex justify-center items-center border-t-[1px] py-1 border-[var(--strokes-separator-separator-light)] dark:border-[#2D2E2F]">
              <CustomDataLoading />
            </div>
          )}
        </div>
      </div>

      {counter === 5 && (
        <div
          ref={step6Ref}
          className="2xl:flex xl:flex lg:hidden md:hidden sm:hidden mobile:hidden  absolute min-w-[354px] min-h-[76px] rounded-[16px] top-0 right-[102%] flex justify-start items-start bg-[var(--bacground-component-module-light)] dark:bg-[var(--bacground-component-module-dark)] py-4 pl-4 pr-8 z-50"
        >
          <div className="absolute -right-2 top-1/2 -translate-y-1/2 w-[24px] h-[24px] flex justify-center items-center rounded-full bg-[var(--branding-brand-yellow-light)]">
            <span className="text-[14px] text-[#000] font-normal">
              {counter}
            </span>
          </div>
          <p className="text-[18px] text-left text-[#000] dark:text-[var(--content-main-black-dark)] font-normal leading-[21.78px]">
            {t("Vank.Home.Onboarding.Six")}
          </p>
        </div>
      )}
      {/* mensaje onboarding 6 mobile*/}
      {counter === 5 && (
        <div
          ref={step6Refmobile}
          className="2xl:hidden xl:hidden lg:flex md:flex sm:flex mobile:flex absolute mobile-375:w-[16.5rem] mobile:w-[13rem] rounded-[16px] mobile-375:-top-[5.6rem] mobile:-top-[6.5rem] left-0 right-0 mx-auto flex justify-start items-start bg-[var(--bacground-component-module-light)] dark:bg-[var(--bacground-component-module-dark)] pt-[12px] pb-[18px] px-[12px] z-50"
        >
          <div className="absolute left-0 right-0 mx-auto mobile-375:top-[4rem] mobile:top-[5rem] -translate-y-1/2 w-[24px] h-[24px] flex justify-center items-center rounded-full bg-[var(--branding-brand-yellow-light)]">
            <span className="text-[14px] text-[#000] font-normal">
              {counter}
            </span>
          </div>
          <p className="text-[14px] text-center text-[var(--content-main-black-light)] dark:text-[var(--content-main-black-dark)] font-medium leading-[16.94px]">
            {t("Vank.Home.Onboarding.Six")}
          </p>
        </div>
      )}

      <CustomModal isOpen={isConversion}>
        <Convert setIsConversion={setIsConversion} />
      </CustomModal>
      <div className="2xl:hidden xl:hidden lg:hidden sm:hidden md:hidden tablet:hidden mobile:block  ">
        <CustomModal isOpen={isOpenModalMobile}>
          <ModalTableMovementsMobil
            listTransactions={listTransactions}
            isLoadingHistory={isLoadingHistory}
            Onclose={handleClickOpenModalTransaction}
            className="min-h-[50%] h-[90%] max-h-[88%]"
          />
        </CustomModal>
      </div>
    </div>
  );
};

export default LatestTransactions;
